import React, { Suspense, Component } from 'react';
import _ /* ,{ toUpper } */ from 'lodash';
import { Helmet } from 'react-helmet';
import {
    Route,
    withRouter,
    Switch,
    Redirect /*, Link */,
} from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import moment from 'moment';
import YouTube from 'react-youtube';

import { saveLocalCart } from '../local-storage';
import { saveReferral, saveCoupon, saveAffiliates } from '../session-storage';
import { recordReferral } from '../scripts/tracking';
import { fetchNavigationData } from '../actions/items';
import { fetchCartData, integrateCarts } from '../actions/carts';
import { fetchConnectedUsersData } from '../actions/users';
import { fetchConfiguratorData } from '../actions/configurator';

import NotFoundPage from './NotFoundPage';
import ErrorBoundary from './ErrorBoundary';
import Banner from './Banner';
import FrontPage from './FrontPage';
import Footer from './Footer';
import Loading from './Loading';
import SearchPage from './SearchPage';
import NHeader from './Header';

// import { recordPopupSuccess, recordPopupClose } from '../scripts/tracking';

import './css/styles.css';

// import './styling/App.css';
const ErrorPage = React.lazy(() => import('./ErrorPage'));
const LoginPage = React.lazy(() => import('./LoginPage'));
const SignupPage = React.lazy(() => import('./SignupPage'));
const ResetPage = React.lazy(() => import('./ResetPage'));
const ConfirmPage = React.lazy(() => import('./ConfirmPage'));
const AccountPage = React.lazy(() => import('./AccountPage'));
const TryKencoPage = React.lazy(() => import('./TryKencoPage'));
const ProductsPage = React.lazy(() => import('./ProductsPage'));
const BuildPage = React.lazy(() => import('./BuildPage'));
const ConfiguratorPage = React.lazy(() => import('./ConfiguratorPage'));
const PriceGunConfiguratorPage = React.lazy(() =>
    import('./PriceGunConfiguratorPage')
);
const ServicesPage = React.lazy(() => import('./ServicesPage'));
const LossPreventionPage = React.lazy(() => import('./LossPreventionPage'));
const AboutPage = React.lazy(() => import('./AboutPage'));
const ReturnPolicyPage = React.lazy(() => import('./ReturnPolicyPage'));
const PriceGuaranteePage = React.lazy(() => import('./PriceGuaranteePage'));
const PrivacyNoticePage = React.lazy(() => import('./PrivacyNoticePage'));
const FraudPreventionPage = React.lazy(() => import('./FraudPreventionPage'));
const PatentsTrademarksPage = React.lazy(() =>
    import('./PatentsTrademarksPage')
);
const ContactPage = React.lazy(() => import('./ContactPage'));
const BarcodesPage = React.lazy(() => import('./BarcodesPage'));
const CartPage = React.lazy(() => import('./CartPage'));
const InvoicesPage = React.lazy(() => import('./InvoicesPage'));
const FAQ = React.lazy(() => import('./FAQ'));
const CommercialTerms = React.lazy(() => import('./CommercialTerms'));
const UseTerms = React.lazy(() => import('./UseTerms'));
const LandingPage = React.lazy(() => import('./LandingPage'));
const BrandPage = React.lazy(() => import('./BrandPage'));
const CategoryPage = React.lazy(() => import('./CategoryPage'));
const ProductPage = React.lazy(() => import('./ProductPage'));
const FavoritesPage = React.lazy(() => import('./FavoritesPage'));
const SelfLaminatingPage = React.lazy(() => import('./SelfLaminatingPage'));
const ClearancePage = React.lazy(() => import('./ClearancePage'));

const styles = (theme) => ({
    snackbar: {
        backgroundColor: theme.palette.tertiary.main,
    },
    cart: {
        marginRight: '1em',
        backgroundColor: 'white',
        color: theme.palette.tertiary.main,
        borderRadius: '50%',
        display: 'inline-block',
        padding: '5px',
        width: '20px',
        textAlign: 'center',
    },
});

class App extends Component {
    state = {
        open: false,
        openFavorites: false,
        fullPrinters: null,
        showPopup: moment().isBetween(
            moment('2021-08-06T00:00:00Z'),
            moment('2021-08-14T04:59:59Z')
        ),
        // announcementSeen: false
    };

    componentDidMount = () => {
        const { loggedIn, dispatch, location } = this.props;
        const { search } = location;

        const query = new URLSearchParams(search);
        const source = query.get('source');
        const type = query.get('type');
        const coupon = query.get('coupon');
        const email = query.get('email');
        const affiliates = query.get('affiliates');

        query.delete('source');
        query.delete('type');
        query.delete('coupon');
        query.delete('email');
        query.delete('affiliates');

        if (email && source && type) {
            saveReferral(source, type);
            recordReferral(email, source, type);
        }
        if (coupon) {
            saveCoupon(coupon);
        }
        if (affiliates) {
            saveAffiliates(affiliates.split(','));
        }

        if (search) {
            window.history.replaceState(
                null,
                '',
                `${window.origin}${window.location.pathname}${
                    query.toString() ? '?' : ''
                }${query.toString()}`
            );
        }

        if (loggedIn) {
            dispatch(fetchCartData());
            dispatch(fetchConnectedUsersData());
        } else {
            window.addEventListener('beforeunload', () => this.persistCart());
        }

        dispatch(fetchNavigationData());
        dispatch(fetchConfiguratorData());
    };

    componentDidUpdate = async (prevProps) => {
        const {
            loggedIn,
            location,
            dispatch,
            localCart,
            cart,
            data,
            currentUser,
        } = this.props;
        const { pathname, search } = location;
        const { favorites } = currentUser;
        const { location: oldLocation } = prevProps;
        const { pathname: oldPathname, search: oldSearch } = oldLocation;
        const { error, fullPrinters } = this.state;

        if (error) {
            this.setState({ error: false });
        }

        if (pathname !== oldPathname || search !== oldSearch) {
            window.scrollTo(0, 0);
        }

        if (loggedIn) {
            if (localCart.length && cart) {
                dispatch(integrateCarts());
            }

            if (!cart) {
                dispatch(fetchCartData());
                dispatch(fetchConnectedUsersData());
            }
        }

        if (!_.isEmpty(localCart)) {
            if (localCart.length > prevProps.localCart.length) {
                this.setState({ open: true });
            }
        } else if (cart && prevProps.cart) {
            if (cart.contents.length > prevProps.cart.contents.length) {
                this.setState({ open: true });
            }
        }

        if (favorites && prevProps.currentUser.favorites) {
            if (favorites.length > prevProps.currentUser.favorites.length) {
                this.setState({ openFavorites: true });
            }
        }

        if (data && !fullPrinters) {
            const models = data.manufacturers.reduce(
                (allModels, mfg) => [
                    ...allModels,
                    ...mfg.models.map((model) => ({
                        ...model,
                        manufacturer: mfg.manufacturer,
                    })),
                ],
                []
            );
            const newFullPrinters = data.printers.map((printer) => {
                const model = models.find(
                    (model) => model.model === printer.model
                );

                return {
                    printer,
                    model,
                };
            });

            this.setState({ fullPrinters: newFullPrinters });
        }
    };

    resetError = () => this.setState({ error: false });

    componentWillUnmount = () =>
        window.removeEventListener('beforeunload', () => this.persistCart());

    persistCart = () => {
        const { localCart } = this.props;
        saveLocalCart(localCart);
    };

    handleClose = () => this.setState({ open: false, openFavorites: false });

    render() {
        const {
            classes,
            loggedIn,
            categories,
            currentUser,
            history,
            data,
            cart,
            localCart,
        } = this.props;
        const { location } = history;
        const { pathname } = location;
        const { open, openFavorites, fullPrinters, showPopup } = this.state;

        const showPay =
            currentUser &&
            (currentUser.anonymous ||
                (currentUser.permissions &&
                    currentUser.permissions.modifyBilling));

        return (
            <div
                className="App"
                style={
                    showPopup ? { height: '100vh', overflowY: 'hidden' } : {}
                }
            >
                <Helmet>
                    <title>Kenco Label &amp; Tag</title>
                    <meta
                        name="description"
                        content="Kenco Label &amp; Tag has been in business for over 40 years and is one of the most advanced label manufacturers in the world."
                    />
                    <meta name="robots" content="noindex" />
                </Helmet>
                {/* <Header
					showSearch={history.location.pathname !== '/'}
					openLiveChatEnglish={this.openLiveChatEnglish}
					openLiveChatSpanish={this.openLiveChatSpanish}
				/> */}
                <NHeader
                    loggedIn={loggedIn}
                    currentUser={currentUser}
                    showPay={showPay}
                    cart={cart}
                    localCart={localCart}
                />
                {/* <div className="lower-header-announcement" style={{ display: noDisplay ? 'none' : '', fontSize: pathname !== '/' ? 18 : '' }}>
					<div style={{ width: '90%' }}>
						<span>We are currently experiencing technical difficulties with our phone systems.</span>
						<br></br>
						<span>We will reach out to customers as soon as the issue is resolved.</span>
					</div>
				</div> */}
                {/* <Fade in={isFrontPage && !announcementSeen}>
					<div className="covid-announcement-container" style={!isFrontPage || announcementSeen ? { display: 'none' } : {}}>
						<div className="covid-announcement">
							<div className="covid-announcement-text">
								<h2 className="covid-header">COVID-19 Announcement</h2>
								<span>Due to the overwhelming need for personal protective equipment (PPE) during the COVID-19 global pandemic, Kenco is now offering several PPE products on our website.</span>
							</div>
							<div className="covid-buttons" style={tooSmall ? { display: 'flex', justifyContent: 'space-between', padding: '15px 0 0 0' } : {}}>
								<Link to="/products?show=300&lowest=true" style={tooSmall ? {} : { marginRight: 25 }}>
									<Button variant="contained" color="primary" size="large" style={tooSmall ? { width: '100%' } : {}} onClick={() => { recordPopupSuccess(customerID); this.setState({ announcementSeen: true }); }}>{tooSmall ? 'View' : 'View COVID-19 Products'}</Button>
								</Link>
								<Button variant={tooSmall ? 'text' : 'outlined'} color="primary" size="large" onClick={() => { recordPopupClose(customerID); this.setState({ announcementSeen: true }); }}>{tooSmall ? 'Got it' : 'Understood, thanks'}</Button>
							</div>
						</div>
					</div>
				</Fade> */}
                <ErrorBoundary
                    loggedIn={loggedIn}
                    currentUser={currentUser}
                    history={history}
                    pathname={pathname}
                >
                    <Suspense fallback={<Loading />}>
                        <Switch>
                            <Route
                                exact
                                path="/error"
                                render={() => (
                                    <ErrorPage
                                        history={history}
                                        pathname={pathname}
                                        resetError={this.resetError}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/login"
                                render={() => <LoginPage history={history} />}
                            />
                            <Route
                                exact
                                path="/signup"
                                render={() => <SignupPage history={history} />}
                            />
                            <Route
                                exact
                                path="/reset"
                                render={() => <ResetPage history={history} />}
                            />
                            <Route
                                path="/confirm"
                                render={() => (
                                    <ConfirmPage
                                        {...this.props}
                                        loggedIn={loggedIn}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/account"
                                render={() => <AccountPage history={history} />}
                            />
                            <Route
                                exact
                                path="/loading/kencoBK1kenco286"
                                render={() => {
                                    return (
                                        <div>
                                            <div className="page-holder">
                                                <Banner label="Loading Kenco BK1 & 286" />
                                                <div className="about-page-container page-container static-container">
                                                    <div
                                                        className="about-page-contents"
                                                        style={{
                                                            display: 'flex',
                                                            flexFlow:
                                                                'row wrap',
                                                            justifyContent:
                                                                'space-around',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        <div>
                                                            <h2>Kenco BK1</h2>
                                                            <YouTube
                                                                videoId="rBwt7HsI6eY"
                                                                opts={{
                                                                    height: '300px',
                                                                    width: '500px',
                                                                }}
                                                            />
                                                        </div>
                                                        <div>
                                                            <h2>Kenco 286</h2>
                                                            <YouTube
                                                                videoId="BwYzXQGjELU"
                                                                opts={{
                                                                    height: '300px',
                                                                    width: '500px',
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }}
                            ></Route>
                            <Route
                                exact
                                path="/try"
                                render={() => (
                                    <TryKencoPage history={history} />
                                )}
                            />
                            <Route
                                exact
                                path="/products"
                                render={() => (
                                    <ProductsPage history={history} />
                                )}
                            />
                            <Route
                                path="/services"
                                render={() => <ServicesPage />}
                            />
                            <Route
                                exact
                                path="/build/parse"
                                render={() =>
                                    data ? (
                                        <ConfiguratorPage {...this.props} />
                                    ) : (
                                        <Loading />
                                    )
                                }
                            />
                            <Route
                                exact
                                path="/build/rolls"
                                render={() =>
                                    data ? (
                                        <ConfiguratorPage
                                            {...this.props}
                                            type="rolls"
                                        />
                                    ) : (
                                        <Loading />
                                    )
                                }
                            />
                            <Route
                                exact
                                path="/build/sheets"
                                render={() =>
                                    data ? (
                                        <ConfiguratorPage
                                            {...this.props}
                                            type="sheets"
                                        />
                                    ) : (
                                        <Loading />
                                    )
                                }
                            />
                            <Route
                                exact
                                path="/build/custom"
                                render={() =>
                                    data ? (
                                        <ConfiguratorPage
                                            {...this.props}
                                            type="custom"
                                        />
                                    ) : (
                                        <Loading />
                                    )
                                }
                            />
                            <Route
                                exact
                                path="/build/price-gun"
                                render={() => (
                                    <PriceGunConfiguratorPage
                                        history={history}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/build"
                                render={() => <BuildPage history={history} />}
                            />
                            <Route
                                path="/lp"
                                render={() => <LossPreventionPage />}
                            />
                            <Route
                                exact
                                path="/about"
                                render={() => <AboutPage />}
                            />
                            <Route
                                exact
                                path="/returns"
                                render={() => <ReturnPolicyPage />}
                            />
                            <Route
                                exact
                                path="/price-match"
                                render={() => <PriceGuaranteePage />}
                            />
                            <Route
                                exact
                                path="/contact"
                                render={() => <ContactPage />}
                            />
                            <Route
                                exact
                                path="/barcodes"
                                render={() => <BarcodesPage />}
                            />
                            <Route
                                exact
                                path="/privacy"
                                render={() => <PrivacyNoticePage />}
                            />
                            <Route
                                exact
                                path="/fraud-prevention"
                                render={() => <FraudPreventionPage />}
                            />
                            <Route
                                exact
                                path="/patents-trademarks"
                                render={() => <PatentsTrademarksPage />}
                            />
                            <Route
                                exact
                                path="/cart"
                                render={() => <CartPage history={history} />}
                            />
                            <Route
                                exact
                                path="/pay"
                                render={() => {
                                    if (showPay) {
                                        return (
                                            <InvoicesPage history={history} />
                                        );
                                    }

                                    return <Redirect to="/" />;
                                }}
                            />
                            <Route exact path="/faq" render={() => <FAQ />} />
                            <Route
                                exact
                                path="/about"
                                render={() => <AboutPage />}
                            />
                            <Route
                                path="/search"
                                render={() => <SearchPage history={history} />}
                            />
                            <Route
                                path="/clearance"
                                render={() => <ClearancePage />}
                            />
                            <Route
                                exact
                                path="/labels/address"
                                render={() => (
                                    <LandingPage
                                        history={history}
                                        brand="address"
                                        group="generic"
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/uninet"
                                render={() => (
                                    <LandingPage
                                        history={history}
                                        brand="uninet"
                                        group="generic"
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/primera/labels"
                                render={() => (
                                    <LandingPage
                                        history={history}
                                        brand="primera"
                                        group="generic"
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/primera/lx400-px450"
                                render={() => (
                                    <LandingPage
                                        history={history}
                                        brand="primera"
                                        group="400_450"
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/primera/lx500-lx500c"
                                render={() => (
                                    <LandingPage
                                        history={history}
                                        brand="primera"
                                        group="500_500c"
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/primera/lx800-lx810"
                                render={() => (
                                    <LandingPage
                                        history={history}
                                        brand="primera"
                                        group="800_810"
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/primera/lx900-lx910"
                                render={() => (
                                    <LandingPage
                                        history={history}
                                        brand="primera"
                                        group="900_910"
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/primera/lx1000-lx2000"
                                render={() => (
                                    <LandingPage
                                        history={history}
                                        brand="primera"
                                        group="1000_2000"
                                    />
                                )}
                            />
                            <Route
                                path="/brands/:brand"
                                render={() => <BrandPage history={history} />}
                            />
                            <Route
                                path="/all-products"
                                render={() => (
                                    <CategoryPage history={history} />
                                )}
                            />
                            <Route
                                path="/item/:sku"
                                render={() => <ProductPage history={history} />}
                            />
                            <Route
                                path="/category/:category"
                                render={() => (
                                    <CategoryPage history={history} />
                                )}
                            />
                            <Route
                                path="/favorites"
                                render={() => <FavoritesPage />}
                            />
                            <Route
                                exact
                                path="/terms/use"
                                render={() => <UseTerms />}
                            />
                            <Route
                                exact
                                path="/terms/commercial"
                                render={() => <CommercialTerms />}
                            />
                            <Route
                                exact
                                path="/self-laminating"
                                render={() => (
                                    <SelfLaminatingPage
                                        currentUser={currentUser}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/templates/lip-balm"
                                render={() => (
                                    <LandingPage
                                        history={history}
                                        brand="templates"
                                        group="lipbalm"
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/"
                                render={() => (
                                    <FrontPage fullPrinters={fullPrinters} />
                                )}
                            />
                            <Route
                                path="*"
                                render={() => (
                                    <NotFoundPage history={history} />
                                )}
                                status={404}
                            />
                        </Switch>
                    </Suspense>
                </ErrorBoundary>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={open}
                    onClick={() => history.push('/cart')}
                    onClose={() => this.handleClose()}
                    autoHideDuration={3000}
                >
                    <SnackbarContent
                        className={classes.snackbar}
                        message={
                            <span>
                                <div className={classes.cart}>
                                    <i className="fas fa-shopping-cart" />
                                </div>
                                <span>ADDED </span>
                                <span> TO CART</span>
                            </span>
                        }
                    />
                </Snackbar>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={openFavorites}
                    onClose={() => this.handleClose()}
                    autoHideDuration={3000}
                >
                    <SnackbarContent
                        className={classes.snackbar}
                        message={
                            <span>
                                <div className={classes.cart}>
                                    <i className="fas fa-heart" />
                                </div>
                                <span>ADDED </span>
                                <span> TO FAVORITES</span>
                            </span>
                        }
                    />
                </Snackbar>
                <Footer
                    showBorder={history.location.pathname !== '/'}
                    categories={categories}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { localCart } = state.localCart;
    const { currentUser } = state.auth;
    const { categories, brands, items } = state.items;
    const { cart } = state.carts;
    const { data } = state.configurator;

    return {
        loggedIn: currentUser && !currentUser.anonymous,
        currentUser,
        categories,
        brands,
        items,
        localCart,
        cart,
        data,
    };
};

export default withRouter(
    connect(mapStateToProps)(withStyles(styles, { withTheme: true })(App))
);
