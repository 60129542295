import React from 'react';

// import './styling/Banner.css';

export default function Banner(props) {
	const { label } = props;

	return (
		<div className="banner-container">
			<div className="banner">
				<h1 className="banner-title">{label}</h1>
			</div>
		</div>
	)
}