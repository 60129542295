import React from 'react';
import _ from 'lodash';
import pluralize from 'pluralize';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import { postCartData } from '../actions/carts';
import { updateLocalCart } from '../actions/local-cart';
import { recordProductAdd } from '../scripts/tracking';

import { HIDE_CATEGORIES, ITEM_IMG_URL } from '../config';
import { updateCustomerFavorites } from '../actions/users';

// import './styling/ProductBox.css';

const styles = (theme) => ({
    name: {
        fontSize: '1.1em',
        lineHeight: '1.25em',
        fontWeight: 500,
        width: 250,
        color: theme.palette.primary.main,
        position: 'relative',
        zIndex: 5,
        margin: '0.5rem auto',
        height: 45,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
    },
    fab: {
        position: 'absolute',
        right: -15,
        zIndex: 5,
        height: 36,
        width: 10,
        minWidth: 10,
        padding: '0 18px',
        borderRadius: 20,
    },
    fabCart: {
        top: 0,
    },
    fabInfo: {
        top: 50,
    },
});

function ProductBox(props) {
    const { item, picture, overlay, type, classes, open, loggedIn, url } =
        props;

    const dispatch = useDispatch();
    const { currentUser } = useSelector((state) => state.auth);

    const { anonymous, favorites } = currentUser;

    const addToCart = (event) => {
        event.stopPropagation();
        event.preventDefault();

        recordProductAdd(item, 1);

        if (loggedIn) {
            dispatch(postCartData(item, 1));
        } else {
            dispatch(updateLocalCart(item, 1));
        }
    };

    const processPricing = () => {
        if (item) {
            const { pricing, pricingType, msrp } = item;

            if (pricing) {
                let relevantPricing = [];

                const genericPricing = (pricing || [])
                    .filter((obj) => !obj.customerID)
                    .sort((a, b) => b.quantity - a.quantity);

                if (currentUser && !currentUser.anonymous) {
                    const { customerID } = currentUser;
                    const customerSpecificPricing = (pricing || [])
                        .filter((obj) => obj.customerID === customerID)
                        .sort((a, b) => b.quantity - a.quantity);

                    if (!_.isEmpty(customerSpecificPricing)) {
                        relevantPricing = customerSpecificPricing;
                    } else {
                        relevantPricing = genericPricing.filter((priceObj) =>
                            pricingType ? priceObj.type === pricingType : true
                        );
                    }
                } else {
                    relevantPricing = genericPricing.filter((priceObj) =>
                        pricingType ? priceObj.type === pricingType : true
                    );
                }

                const highestQuantityPrice = (relevantPricing.sort(
                    (a, b) => b.quantity - a.quantity
                ) || [])[0];
                const lowestPrice = relevantPricing[0]
                    ? relevantPricing[0].price
                    : 0;
                const lowestPriceString = lowestPrice.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                });

                const msrpString = (msrp || 0).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                });
                const msrpDiff = (msrp || 0) - highestQuantityPrice.price;
                const msrpDiffString = `${
                    msrpDiff > 0
                        ? msrpDiff.toLocaleString('en-US', {
                              style: 'currency',
                              currency: 'USD',
                          })
                        : ''
                }`;

                return {
                    hasTier: relevantPricing.length > 1,
                    lowestPriceString,
                    msrpString,
                    msrpDiffString,
                };
            }
        }

        return null;
    };

    if (item) {
        const { id, name, sku, discontinued, variations, availability } = item;

        const showFavorites = !anonymous;
        const isFavorite = favorites && favorites.includes(sku);
        const variation = variations ? variations[0] : null;
        const { count, type: variationType } = variation || {};

        const { hasTier, lowestPriceString, msrpString, msrpDiffString } =
            processPricing() || {};

        return (
            <div
                style={
                    type === 'category' && HIDE_CATEGORIES.includes(id)
                        ? { display: 'none' }
                        : {}
                }
            >
                <Link to={url} onClick={open}>
                    <div className="product-box-container hide-desktop">
                        <div
                            className={`product-box ${
                                type === 'category'
                                    ? 'product-box-tan'
                                    : 'product-box-blue'
                            }`}
                        >
                            <div className="product-box-image">
                                {picture && (
                                    <img
                                        src={`${ITEM_IMG_URL}${picture}`}
                                        alt={name}
                                        className={`product-item-image ${
                                            type === 'category' &&
                                            'product-category-image'
                                        }`}
                                    />
                                )}
                            </div>
                            <div className="product-box-title-container">
                                {overlay && (
                                    <div className="product-box-image-overlay-mobile">
                                        {overlay}
                                    </div>
                                )}
                                <span
                                    className={`product-box-title ${
                                        name.length > 70
                                            ? 'small-product-box-title'
                                            : ''
                                    }`}
                                >
                                    {name}
                                </span>
                            </div>
                        </div>
                    </div>
                </Link>
                <div
                    className="hide-mobile"
                    style={{ display: 'flex', justifyContent: 'center' }}
                >
                    <div
                        className="product-box-desktop"
                        style={type === 'category' ? { margin: 0 } : {}}
                    >
                        <Link
                            to={url}
                            onClick={open}
                            className="product-box-link"
                        >
                            <img
                                src={`${ITEM_IMG_URL}${picture}`}
                                alt={name}
                                className={`product-box-image-desktop ${
                                    type === 'category' &&
                                    'product-category-image'
                                }`}
                            />
                            {overlay && (
                                <div className="product-box-image-overlay">
                                    {overlay}
                                </div>
                            )}
                            {!!count ? (
                                <div
                                    className="condensed product-box-variant"
                                    style={{ marginRight: 'auto' }}
                                >
                                    +{count}{' '}
                                    {pluralize(
                                        variationType.toLowerCase(),
                                        count
                                    )}
                                </div>
                            ) : (
                                <div
                                    className="condensed product-box-variant"
                                    style={{ height: 21 }}
                                />
                            )}
                            <Typography component="h2" className={classes.name}>
                                <Tooltip
                                    title={
                                        <span
                                            className="condensed"
                                            style={{ fontSize: '14px' }}
                                        >
                                            {name}
                                        </span>
                                    }
                                >
                                    <span className="product-box-name-desktop">
                                        {name}
                                    </span>
                                </Tooltip>
                            </Typography>
                        </Link>
                        {type === 'item' && (
                            <>
                                <div
                                    className="condensed"
                                    style={{
                                        display: 'flex',
                                        alignItems: 'start',
                                        gap: '1rem',
                                        margin: '0.25rem auto 0.25rem 0',
                                    }}
                                >
                                    <div
                                        className="condensed"
                                        style={{
                                            fontSize: '1.3rem',
                                            color: 'black',
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontWeight: 500,
                                            }}
                                        >
                                            {lowestPriceString}
                                        </span>
                                        {hasTier && <span> and up</span>}
                                    </div>
                                    {msrpDiffString && (
                                        <div
                                            style={{
                                                textDecoration: 'line-through',
                                                color: 'gray',
                                            }}
                                        >
                                            {msrpString}
                                        </div>
                                    )}
                                </div>
                                <div
                                    className="condensed"
                                    style={{
                                        color: '#dba124',
                                        margin: '-0.25rem auto 0.25rem 0',
                                        height: 21,
                                    }}
                                >
                                    {msrpDiffString
                                        ? `Save ${msrpDiffString}`
                                        : ''}
                                </div>
                                <div
                                    style={{
                                        fontSize: '0.9rem',
                                        color: 'black',
                                        marginRight: 'auto',
                                        height: 40,
                                    }}
                                >
                                    {availability
                                        ? `${availability[0].toUpperCase()}${availability
                                              .slice(1)
                                              .toLowerCase()}`
                                        : ''}
                                </div>
                                <ButtonGroup
                                    style={{
                                        marginTop: '1rem',
                                        width: 250,
                                    }}
                                >
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disableElevation
                                        style={{
                                            flex: 1,
                                        }}
                                        disabled={discontinued}
                                        onClick={(e) => addToCart(e)}
                                    >
                                        {discontinued
                                            ? 'Call to Order'
                                            : 'Add to Cart'}
                                    </Button>
                                    {showFavorites && !discontinued && (
                                        <Tooltip
                                            title={
                                                isFavorite
                                                    ? 'Remove from Favorites'
                                                    : 'Add to Favorites'
                                            }
                                            placement="right"
                                        >
                                            <Button
                                                color="secondary"
                                                variant={
                                                    isFavorite
                                                        ? 'contained'
                                                        : 'outlined'
                                                }
                                                disableElevation
                                                onClick={(e) => {
                                                    e.preventDefault();

                                                    if (isFavorite) {
                                                        dispatch(
                                                            updateCustomerFavorites(
                                                                sku,
                                                                'delete'
                                                            )
                                                        );
                                                    } else {
                                                        dispatch(
                                                            updateCustomerFavorites(
                                                                sku,
                                                                'add'
                                                            )
                                                        );
                                                    }
                                                }}
                                            >
                                                {isFavorite ? (
                                                    <i className="fas fa-heart" />
                                                ) : (
                                                    <i className="far fa-heart" />
                                                )}
                                            </Button>
                                        </Tooltip>
                                    )}
                                </ButtonGroup>
                            </>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    return '';
}

export default withStyles(styles, { withTheme: true })(ProductBox);
